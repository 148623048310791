import { CookieStorage } from 'cookie-storage';
import {
	AJS_ANONYMOUS_ID,
	MARKETPLACE_USER_LOCATION,
	OUTDOOR_DISMISSAL_COUNT,
	VENDOR_REFERRED_MARKET_CODE,
} from '../../../constants/cookies';

const personalisationKeys = [
	MARKETPLACE_USER_LOCATION,
	OUTDOOR_DISMISSAL_COUNT,
];
const analyticKeys = [VENDOR_REFERRED_MARKET_CODE];
const necessaryKeys = [AJS_ANONYMOUS_ID];
const socialKeys = [];

const NECESSARY = 'Necessary'; // C0001
const ANALYTIC = 'Analytic'; // C0002
const PERSONALISATION = 'Personalisation'; // C0003 - Adam Keys, we are using the British spelling
/* eslint-disable @typescript-eslint/no-unused-vars */
const TARGETING = 'Targeting'; // C0004
const SOCIAL = 'Social'; // C0005,  'Custom' C0006

export function cookieSetItem(key, value, options = {}) {
	const cookieStorage = new CookieStorage();
	const consentCategories =
		window.UnionConsentManagement.getConsentedCategories();
	const localOptions = options;
	let setItem = false;

	localOptions.path = '/'; // force a consistent path

	// categories include [Necessary,Analytic,Social,Personalisation]
	setItem =
		setItem ||
		(consentCategories.includes(NECESSARY) && necessaryKeys.includes(key));
	setItem =
		setItem ||
		(consentCategories.includes(ANALYTIC) && analyticKeys.includes(key));
	setItem =
		setItem || (consentCategories.includes(SOCIAL) && socialKeys.includes(key));
	setItem =
		setItem ||
		(consentCategories.includes(PERSONALISATION) &&
			personalisationKeys.includes(key));

	if (!setItem) {
		return;
	}

	cookieStorage.setItem(key, value, localOptions);
}

export const cookieGetItem = (key) => {
	const cookieStorage = new CookieStorage();
	return cookieStorage.getItem(key);
};

export default cookieSetItem;
