export const codeToSlugNameMap = {
	BAR: {
		href: 'bar-services',
		name: 'Bar Services',
		shortName: 'Bar Services',
	},
	BEA: { href: 'beauty-services', name: 'Beauty', shortName: 'Beauty' },
	BWP: {
		href: 'bridal-salons',
		name: 'Bridal Salons',
		shortName: 'Bridal Salons',
	},
	CAT: { href: 'catering', name: 'Caterers', shortName: 'Caterers' },
	DNC: {
		href: 'wedding-dance-lessons',
		name: 'Dance Lessons',
		shortName: 'Dance Lessons',
	},
	DEC: { href: 'wedding-decor-shops', name: 'Decor', shortName: 'Decor' },
	DJS: { href: 'wedding-djs', name: 'DJs', shortName: 'DJs' },
	ENS: {
		href: 'wedding-soloists-ensembles',
		name: 'Ensembles + Soloists',
		shortName: 'Ensembles + Soloists',
	},
	FAV: { href: 'favors', name: 'Favors + Gifts', shortName: 'Favors + Gifts' },
	FLO: { href: 'florists', name: 'Florists', shortName: 'Florists' },
	HRB: {
		href: 'wedding-room-blocks',
		name: 'Hotel Room Blocks',
		shortName: 'Hotel Room Blocks',
	},
	INV: {
		href: 'invitations',
		name: 'Invitations + Paper Goods',
		shortName: 'Invitations + Paper Goods',
	},
	JWL: { href: 'jewelers', name: 'Jewelers', shortName: 'Jewelers' },
	OPC: {
		href: 'wedding-officiants',
		name: 'Officiants + Premarital Counseling',
		shortName: 'Officiants + Premarital Counseling',
	},
	PHB: {
		href: 'wedding-photo-booth-rentals',
		name: 'Photo Booths',
		shortName: 'Photo Booths',
	},
	REC: {
		href: 'wedding-reception-venues',
		name: 'Reception Venues',
		shortName: 'Reception Venues',
	},
	SPV: {
		href: 'rehearsal-dinners-bridal-showers',
		name: 'Rehearsal Dinners, Bridal Showers + Parties',
		shortName: 'Rehearsal Dinners, Bridal Showers + Parties',
	},
	RNT: { href: 'wedding-rentals', name: 'Rentals', shortName: 'Rentals' },
	TRA: {
		href: 'transportation-services',
		name: 'Transportation',
		shortName: 'Transportation',
	},
	TRV: {
		href: 'wedding-travel-agents',
		name: 'Travel Specialists',
		shortName: 'Travel Specialists',
	},
	VID: {
		href: 'wedding-videographers',
		name: 'Videographers',
		shortName: 'Videographers',
	},
	BDS: {
		href: 'live-wedding-bands',
		name: 'Wedding Bands',
		shortName: 'Bands',
	},
	WCK: {
		href: 'wedding-cake-bakeries',
		name: 'Wedding Cakes',
		shortName: 'Cakes',
	},
	WPH: {
		href: 'wedding-photographers',
		name: 'Wedding Photographers',
		shortName: 'Photographers',
	},
	PLN: {
		href: 'wedding-planners',
		name: 'Wedding Planners',
		shortName: 'Wedding Planners',
	},
};

const sortedCategories = (sortKey) =>
	Object.entries(codeToSlugNameMap)
		.sort((a, b) => a[1][sortKey].localeCompare(b[1][sortKey]))
		.map((ary) => ary[0]);

export const categoriesSortedByName = sortedCategories('name');

export const categoriesSortedByShortName = sortedCategories('shortName');

export const categorySlugs = Object.entries(codeToSlugNameMap).map(
	(category) => category[1].href,
);

export const categorySlugsRegex =
	'bridal-accessory-shops|dress-preservation-cleaning|bar-services|beauty-services|bed-and-breakfasts|boudoir-photographers|bridal-salons|calligraphers|catering|ceremony-accessories|wedding-ceremony-venues|wedding-dance-lessons|wedding-decor-shops|desserts|wedding-djs|wedding-soloists-ensembles|favors|fitness|florists|wedding-room-blocks|invitations|jewelers|wedding-lighting|wedding-menswear|newlywed-services|wedding-officiants|wedding-photo-booth-rentals|wedding-reception-venues|registry-services|rehearsal-dinners-bridal-showers|wedding-rentals|service-staff|technologies|transportation-services|wedding-travel-agents|vacation-homes-villas|variety-acts|wedding-videographers|live-wedding-bands|wedding-cake-bakeries|wedding-designers|wedding-jewelry-stores|wedding-photographers|wedding-planners';

// Google Maps are not used in Wedding Receptions, Bridal Salons and Hotel Room Blocks
export const categoryHasMap = (code) =>
	!['REC', 'BWD', 'HRB'].some((x) => x === code);

export default codeToSlugNameMap;
