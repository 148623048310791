import { awardsInfo } from '../../client/utils/awardsInfo';

const { bow } = awardsInfo();
const bowCurrentYear = bow.year;

export default {
	BOW_CURRENT_YEAR: bowCurrentYear,
	CHANGED_AWARD_TYPE: 'CHANGED_AWARD_TYPE',
	CHANGED_CATEGORY: 'CHANGED_CATEGORY',
	CHANGED_LOCATION: 'CHANGED_LOCATION',
	CURRENT_WINNERS: {
		name: `${bowCurrentYear} Best of Weddings`,
		slug: 'current-winners',
	},
	DEFAULT_CATEGORY: {
		name: 'Reception Venues',
		code: 'REC',
		id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		slug: 'wedding-reception-venues',
	},
	FETCH_VENDORS: 'FETCH_VENDORS',
	FETCH_VENDORS_SUCCESS: 'FETCH_VENDORS_SUCCESS',
	FETCH_VENDORS_ERROR: 'FETCH_VENDORS_ERROR',
	HALL_OF_FAME: { name: 'Hall of Fame', slug: 'hall-of-fame' },
	TAXONOMY_TERM_AWARD_WINNERS: { ID: 'c59bdf9a-6a9e-423c-8332-3c3aca6df62b' },
};
