import React from 'react';

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */
export default function generateAsyncRouteComponent({ loader, Placeholder }) {
	let LoadedComponent = null;
	return class AsyncRouteComponent extends React.Component {
		/**
		 * Static so that you can call load against an uninstantiated version of
		 * this component. This should only be called one time outside of the
		 * normal render path.
		 */
		static load() {
			return loader().then((mdl) => {
				LoadedComponent = mdl.default || mdl;
			});
		}

		constructor() {
			super();

			this.state = {
				LoadedComponent,
			};
		}

		componentDidMount() {
			AsyncRouteComponent.load().then(this.updateState);
		}

		shouldComponentUpdate(_, nextState) {
			return !!nextState.LoadedComponent;
		}

		updateState = () => {
			if (this.state.LoadedComponent !== LoadedComponent) {
				this.setState({
					LoadedComponent,
				});
			}
		};

		render() {
			const { LoadedComponent: ComponentFromState } = this.state;
			if (ComponentFromState) {
				return <ComponentFromState {...this.props} />;
			}

			if (Placeholder) {
				return <Placeholder {...this.props} />;
			}

			return null;
		}
	};
}
