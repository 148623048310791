// For future reference & context: Created in TKMPTN-119
export const valid = (value: string) => {
	if (value === null) return false;
	return regexp().test(value);
};

const regexp = () => {
	return strictRegexp();
};

const strictRegexp = () => {
	return new RegExp(`^${localPartPattern()}@${domainPartPattern()}$`, 'i');
};

const localPartPattern = () => {
	return `${atomChar()}(?:\\.?${atomChar()}){0,63}`;
};

const domainPartPattern = () => {
	return fqdnPattern();
};

const fqdnPattern = () => {
	return `(?=.{1,255}$)(?:${hostLabelPattern()}\\.)*${domainLabelPattern()}`;
};

const hostLabelPattern = () => {
	return `${labelIsCorrectLength()}${alnum()}(?:${alnumhy()}{0,61}${alnum()})?`;
};

const domainLabelPattern = () => {
	return `${hostLabelPattern()}\\.${tldLabelPattern()}`;
};

const tldLabelPattern = () => {
	return `${alpha()}{1,64}`;
};

const labelIsCorrectLength = () => {
	return '(?=[^.]{1,63}(?:\\.|$))';
};

const atomChar = () => {
	return '[-a-zA-Z0-9+_!"\'#$%^&*{}/=?`|~]';
};

const alpha = () => {
	return '[a-zA-Z]';
};

const alnum = () => {
	return '[a-zA-Z0-9]';
};

const alnumhy = () => {
	return '(?:[a-zA-Z0-9-])';
};
