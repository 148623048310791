// Due to product reasons, we don't want an infinite scroll carousel...
// but we do want a centered carousel...
// but we don't want white space to the left of the centered image on load.
// So this prepends n-items from the end of the array

export default function reorderMedia(media, carouselLeftImageCount) {
	if (!media) {
		return [];
	}

	if (media.length === 1) {
		return media;
	}

	const itemsToTheLeftOfMainImage = media.slice(-1 * carouselLeftImageCount);

	if (carouselLeftImageCount > media.length) {
		carouselLeftImageCount = media.length;
	}

	// last n-items are prepended to media array
	return [
		...itemsToTheLeftOfMainImage.filter((m) => m),
		...media.filter((m) => m),
	];
}
