// external to Marketplace
export const SESSION_TOKEN = 'xo-session-token';
export const VISITOR_ID = 'visitor_id';
export const VISIT_ID = 'visit_id';
export const AJS_ANONYMOUS_ID = 'ajs_anonymous_id';

// internal Marketplace cookies
export const MARKETPLACE_USER_LOCATION = 'marketplace_user_location';
export const OUTDOOR_DISMISSAL_COUNT = 'outdoor_dismissal_count';
export const VENDOR_REFERRED_MARKET_CODE = 'vendorReferredMarketCode';
export const EXPERIMENT_ASSIGNMENTS = 'assignments';
