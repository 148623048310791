import { constructAnalyticsProperties } from '@utils/constructAnalyticsProperties';
import analyticsWrapper from '@xo-union/tk-analytics';
import type { INPMetricWithAttribution } from 'web-vitals';

const parseMetricsINP = (metric: INPMetricWithAttribution) => {
	const { id, name, rating, value, entries, attribution } = metric;
	const base = {
		metrics_inp_id: id,
		metrics_inp_name: name,
		metrics_inp_rating: rating,
		metrics_inp_value: value,
		metrics_inp_interactionTarget: attribution?.interactionTarget,
	};

	if (entries?.length) {
		return constructAnalyticsProperties({
			...base,
			entries: entries.map((entry) => ({
				metrics_inp_duration: entry.duration,
				metrics_inp_entryType: entry.entryType,
				metrics_inp_interactionId: entry.interactionId,
				metrics_inp_processingEnd: entry.processingEnd,
				metrics_inp_processingStart: entry.processingStart,
				metrics_inp_startTime: entry.startTime,
			})),
		});
	}

	return constructAnalyticsProperties(base);
};

export const prepareINP = (metric: INPMetricWithAttribution) => {
	if (metric.value >= 200) {
		analyticsWrapper.track('Web Vital INP', parseMetricsINP(metric));
	}
};

export const reportWebVitals = () => {
	import('web-vitals/attribution').then(({ onINP }) => {
		onINP(prepareINP, { reportAllChanges: true });
	});
};
