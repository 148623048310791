import BOWConstants from '../../constants/best_of_weddings';

const VendorHelper = {
	awardWinners(vendor) {
		const awardWinners = vendor.affiliates?.find(
			(affiliate) =>
				affiliate.id === BOWConstants.TAXONOMY_TERM_AWARD_WINNERS.ID,
		);

		return awardWinners?.affiliates || [];
	},

	getAwardWinner(vendor, awardName) {
		return this.awardWinners(vendor)?.find(
			(awardWinner) => awardWinner.name === awardName,
		);
	},

	hasAward(vendor) {
		return this.awardWinners(vendor).length > 0;
	},

	isCurrentAndPremiumFeatured(vendor, marketCode) {
		const isCurrent = this.getAwardWinner(
			vendor,
			`${BOWConstants.CURRENT_WINNERS.name} Winner`,
		);
		const isBOWPFV = vendor.salesProfiles.find(
			(obj) =>
				obj.addOns.find((addOn) => addOn.code === 'BOWPFV') &&
				obj.marketCode === marketCode,
		);
		// all need to be in proper saleProfiles market
		// Searching for code match bc some vendors with the BOWPFV award have the addOn award name of:
		// "Best of Weddings-Premium Featured Vendor" while others have "BOW Premium Featured Vendor"

		return !!isCurrent && !!isBOWPFV;
	},

	isHOFAndPremiumFeatured(vendor, marketCode) {
		const isHOF = this.getAwardWinner(
			vendor,
			`${BOWConstants.HALL_OF_FAME.name}`,
		);
		const isHOFPFV = vendor.salesProfiles.find(
			(obj) =>
				obj.addOns.find((addOn) => addOn.code === 'HOFPFV') &&
				obj.marketCode === marketCode,
		);

		return !!isHOF && !!isHOFPFV;
	},

	isBOWPreferredPlacement(vendor, marketCode) {
		const isBOWPP = vendor.salesProfiles.find(
			(obj) =>
				obj.addOns.find((addOn) => addOn.code === 'BOWPP') &&
				obj.marketCode === marketCode,
		);

		return !!isBOWPP;
	},

	bioHasContent(bio) {
		return !!(
			bio &&
			bio.name &&
			bio.displayRole &&
			bio.description &&
			bio.photo?.url
		);
	},

	metaDescription(vendor, category) {
		return `${vendor.name} is a ${category.singular.term} in \
${vendor.address?.city}, ${vendor.address?.state}. Read reviews, \
view photos, see special offers, and contact ${vendor.name} directly on The Knot.`;
	},
};

export default VendorHelper;
