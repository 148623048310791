// @ts-check

/**
 * This file cannot be imported properly from settings/qa and settings/production
 * as a TS file.  Converting to JS and leaving types in for now. -- SG
 */

/**
 *
 * @param {string} [date]
 * @returns {number | null}
 */
const getDate = (date) => {
	if (!date) {
		return null;
	}
	try {
		const sd = new Date(date);
		sd.setDate(sd.getDate());
		const time = sd.getTime();
		if (!Number.isNaN(time)) {
			return time;
		}
	} catch (e) {
		// Do Nothing
	}
	return null;
};

/**
 * @typedef WithinDateRangeConfig
 * @property {string} [startDate]
 * @property {string} [endDate]
 */

/**
 *
 * @param {WithinDateRangeConfig} config
 * @returns {boolean}
 */
export const withinDateRange = (config) => {
	const { startDate, endDate } = config;
	if (!startDate && !endDate) {
		return true;
	}

	const now = Date.now();
	const start = getDate(startDate);
	const end = getDate(endDate);

	if (!start && !end) {
		return false;
	}

	const afterStart = startDate && start ? now > start : true;
	const beforeEnd = endDate && end ? now < end : true;

	return afterStart && beforeEnd;
};

export default withinDateRange;
