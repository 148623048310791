// event name values
export const BREADCRUMB_INTERACTION = 'Breadcrumb Interaction';
export const CLICK_THROUGH_TO_CONTENT = 'Click-through to Content';
export const CLICK_THROUGH_TO_VENDOR_CATEGORY =
	'Click-through to Vendor Category';
export const CLICK_THROUGH_TO_VENDORS = 'Click-through to Vendors';
export const VENDOR_SEARCH_INTERACTION = 'Vendor Search Interaction';
export const WIDGET_VIEWED = 'Widget Viewed';
export const CHANGE_LOCATION = 'change location';
export const CATEGORY_VIEWED = 'Vendor Category Viewed';

// sourceContent values
export const ARTICLES = 'articles';
export const BREADCRUMB = 'breadcrumb';
export const CATEGORIES_LIST_VIEW = 'categories list view';
export const FEATURED_CATEGORIES = 'featured categories';
export const FOOTER = 'footer';
export const MARKETPLACE_SUB_NAV = 'marketplace sub nav';
export const RECOMMENDED_VENDORS = 'recommended vendors';
export const SEARCH = 'search';
export const VIEW_ALL_CTA = 'view all CTA';

// selection values
export const WEDDING_VENDORS = 'Wedding Vendors';
export const SEARCH_COMPLETED = 'search completed';
export const START_TYPING = 'start typing';
export const LOCATION_SELECTED = 'location selected';
export const SELECT_CATEGORY = 'select category';
export const OPEN_CATEGORY_MENU = 'open_category_menu';

// contentType values
export const ARTICLE = 'article';

// sourceFormula values
export const BANDED_DISTANCE = 'banded distance';

// sourcePage values
export const CATEGORY_LANDING_PAGE = 'category landing page';
export const MARKETPLACE_HOME_PAGE = 'marketplace home';
export const STATE_LANDING_PAGE = 'state landing page';
export const CATEGORY_RESULTS_PAGE = 'category results';
export const CITY_SEARCH_PAGE = 'city search page';

// page levels (AKA pageType)
export const AI_USAGE_POLICY = 'ai usage policy';
export const BEST_OF_WEDDINGS_LEVEL = 'best of weddings';
export const CATEGORY_LEVEL = 'category';
export const CITY_LEVEL = 'city';
export const MARKETPLACE_HOME_LEVEL = MARKETPLACE_HOME_PAGE;
export const REVIEW_WEDDING_VENDORS_LEVEL = 'review wedding vendors';
export const STATE_LEVEL = 'state';
export const STOREFRONT_LEVEL = 'storefront';
export const PHOTOS_LEVEL = 'photos';

// ETS Values
export const PRODUCT_MARKETPLACE = 'marketplace';
export const SALES_PRODUCT_STANDARD = 'standard';

// base props
export const clickThroughToContentBaseProps = {
	contentType: ARTICLE,
	sourcePage: CATEGORY_LANDING_PAGE,
};

export const categoryViewed = (pageType, track, code, props) =>
	track({
		event: CATEGORY_VIEWED,
		properties: {
			vendorCategoryCode: code,
			vendorCategoryLevel: pageType,
			...props,
		},
	});

export const widgetViewed = (pageType, code) => ({
	event: WIDGET_VIEWED,
	properties: {
		sourceContent: RECOMMENDED_VENDORS,
		sourceFormula: BANDED_DISTANCE,
		sourcePage: pageType,
		vendorCategoryCode: code,
	},
});

// helpers
export const mapSourcePage = (pageType) => {
	switch (pageType) {
		case STATE_LEVEL:
			return STATE_LANDING_PAGE;
		case CATEGORY_LEVEL:
			return CATEGORY_LANDING_PAGE;
		case CITY_LEVEL:
			return CITY_SEARCH_PAGE;
		default:
			return MARKETPLACE_HOME_PAGE;
	}
};
