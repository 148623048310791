import { matchRoutes } from 'react-router-config';

/**
 * First match the routes via react-router-config's `matchRoutes` function.
 * Then iterate over all of the matched routes, if they've got a load function
 * call it.
 *
 * This helps us to make sure all the async code is loaded before rendering.
 */
export default function ensureAsyncReady(routeConfig, providedLocation) {
	const matches = matchRoutes(
		routeConfig,
		providedLocation || window.location.pathname,
	);
	return Promise.all(
		matches.map((match) => {
			const { component } = match.route;
			if (component && component.load) {
				return component.load();
			}
			return undefined;
		}),
	);
}
