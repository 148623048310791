import { CookieStorage } from 'cookie-storage';
import { VENDOR_REFERRED_MARKET_CODE } from '../../constants/cookies';

const NavigationHelper = {
	referrer() {
		try {
			// eslint-disable-next-line no-undef
			return window.document.referrer || null;
		} catch (err) {
			return err;
		}
	},

	sanitizedVendorReferredMarketCode(defaultMarketCode) {
		try {
			const MARKETPLACE_APP_REGEXP = /\/marketplace[/?]/i;
			const userCameFromMarketplace = MARKETPLACE_APP_REGEXP.test(
				this.referrer(),
			);
			const referredMarketCode = new CookieStorage().getItem(
				VENDOR_REFERRED_MARKET_CODE,
			);

			if (referredMarketCode === 'typeaheadSearch') {
				return null;
			}
			if (userCameFromMarketplace) {
				return referredMarketCode || defaultMarketCode;
			}

			return null;
		} catch (_err) {
			return null;
		}
	},
};

export default NavigationHelper;
