import { awards } from '@settings';
import type { AwardInfo } from 'types/bow';
import { withinDateRange } from '../withinDateRange';

export const awardsInfo = (): AwardInfo => {
	const { bow, hof, startDate } = awards;

	if (withinDateRange({ startDate })) {
		return {
			bow: bow.afterStartDate,
			hof: hof.afterStartDate,
		};
	}

	return {
		bow: bow.beforeStartDate,
		hof: hof.beforeStartDate,
	};
};

export default awardsInfo;
