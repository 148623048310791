import { Logger } from '@utils/logger/logger';

export const waitForAnalytics = () =>
	new Promise((resolve) => {
		const clear = setTimeout(() => {
			resolve(false);

			Logger.error({
				log_message: 'Failed to load analytics',
				errorToLog: new Error('Failed to load analytics'),
				newRelic: true,
			});
		}, 3000);

		window.analytics.ready(() => {
			clearTimeout(clear);
			resolve(true);
		});
	});
